import {
  SINGLE_CHAT_ERROR,
  SINGLE_CHAT_LOADED,
  SINGLE_CHAT_LOADING,
  INSERT_NEW_MESSAGE,
  MESSAGE_SENT,
  EMPTY_SINGLE_CHAT,
} from "../Actions/types";
import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  error: "",
  loading: false,
  chat: null,
  messages: {
    data: [],
    count: 0,
    allPages: 0,
  },
  hasMore: true,
};
const singleChatReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(EMPTY_SINGLE_CHAT, (s) => {
      s = initialState;
    })
    .addCase(INSERT_NEW_MESSAGE, (state, { payload }) => {
      state.messages.data.push({ ...payload });
    })
    .addCase(SINGLE_CHAT_ERROR, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    })
    .addCase(SINGLE_CHAT_LOADING, (state, { payload }) => {
      let knownChat = payload?.knownChat;

      state.loading = true;
      state.error = false;
      if (knownChat) state.chat = knownChat;
    })
    .addCase(SINGLE_CHAT_LOADED, (state, { payload }) => {
      state.error = false;
      state.chat = payload.chat;
      state.otherChats = payload.otherChats;
      state.messages = {
        thisPage: payload.messages.thisPage,
        count: payload.messages.count,
        data:
          payload.loadMore && payload.messages.thisPage !== 1
            ? [...payload.messages.data, ...state?.messages.data]
            : [...payload.messages.data],
      };
      state.hasMore = payload.hasMore;
      state.loading = false;
    })
    .addCase(MESSAGE_SENT, (state, { payload }) => {
      if (payload.newChat) {
        if (payload.chatId !== state?.chat?.id) {
          state.chat.id = payload.chatId;
        }
      }
      let mIndex = state.messages.data.findIndex(
        (c) => c.feMark === parseInt(payload?.feMark)
      );

      if (mIndex > -1) {
        let message = state.messages.data[mIndex];
        state.messages.data[mIndex] = {
          ...message,
          ...payload,
          status: "sent",
        };
      } else {
        if (parseInt(payload.chatId) === state.chat.id)
          state?.messages.data.push({ ...payload });
      }
    });
});

export default singleChatReducer;
