import { configureStore } from "@reduxjs/toolkit";
// import thunkMiddleware from "redux-thunk";
import loginReducer from "./Slices/Login";
import medicalTreatment from "./Slices/MedicalTreatment";
import plans from "./Slices/Plans";
import visa from "./Slices/Visa";
import {
  app,
  auth,
  requests,
  notifications,
  hospitalsReducer,
  doctorsReducer,
  entertainmentReducer,
  transportationReducer,
  logs,
  roles,
  chats,
  singleChat,
} from "./reducers";
import specialization from "./Slices/Specialization";
import doctor from "./Slices/Doctor";
import transportation from "./Slices/Transportation";
import hospital from "./Slices/Hospital";
import tour from "./Slices/Tour";
import entertainmentCategory from "./Slices/EntertainmentCategory";
import hospitalSpecialization from "./Slices/HospitalSpecialization";
import entertainment from "./Slices/Entertainment";
import freelanceSupplier from "./Slices/FreelanceSupplier";
import freelanceSupplierType from "./Slices/FreelanceSupplierType";
import packages from "./Slices/Package";
import operation from "./Slices/Operation";
import flight from "./Slices/Flight";
import { chatMediaSlice } from "./reducers/chatMediaSlice";
import supplierCategory from "./Slices/SupplierCategory";

const store = configureStore({
  reducer: {
    login: loginReducer,
    medicalTreatment,
    plans,
    visa,
    auth,
    app,
    specialization,
    doctor,
    transportation,
    hospital,
    tour,
    packages,
    entertainmentCategory,
    requests,
    hospitalSpecialization,
    entertainment,
    notifications,
    freelanceSupplier,
    freelanceSupplierType,
    logs,
    hospitalsReducer,
    doctorsReducer,
    entertainmentReducer,
    transportationReducer,
    roles,
    operation,
    flight,
    chats,
    singleChat,
    supplierCategory,
    [chatMediaSlice.name]: chatMediaSlice.reducer,
  },
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware().concat(thunkMiddleware),
  // devTools: process.env.NODE_ENV === "production" ? false : true,
});

export default store;
