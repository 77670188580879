import { APP_CONFIG } from "../Actions/types";
import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
};

const appReducer = createReducer(initialState, (builder) => {
  builder.addCase(APP_CONFIG, (s, { payload = {} }) => {
    s.loading = false;
  });
});

export default appReducer;
