import { listNotificationsService } from "../../../Services/notifications";
import { socket } from "../../../connection/socket";

import {
  LIST_NOTIFICATIONS,
  LIST_NOTIFICATIONS_ERROR,
  LIST_NOTIFICATIONS_LOADING,
  INSERT_NEW_NOTIFICATION,
} from "../types";

import SnackUtils from "../../../shared/snacks";

export const getNotificationsAction =
  ({ page, limit, text, type, chatId }) =>
  (dispatch) => {
    dispatch({ type: LIST_NOTIFICATIONS_LOADING });
    return listNotificationsService({
      page,
      limit,
    }).then((d) => {
      if (d.error) {
        dispatch({
          type: LIST_NOTIFICATIONS_ERROR,
          payload: d.error,
        });
      }
      dispatch({
        type: LIST_NOTIFICATIONS,
        payload: d,
      });
    });
  };

export const totalUnReadAction = (totalUnRead) => {
  if (totalUnRead !== 0) {
    socket.sendMessage("read_notifications");
  }
};

export const insertNewNotification = (notification) => (dispatch) => {
  notification?.title && SnackUtils.info(notification?.title);

  dispatch({ type: INSERT_NEW_NOTIFICATION, payload: notification });
};
