import {
  Box,
  Button,
  Checkbox,
  Divider,
  Input,
  Slide,
  TextField,
  Typography,
  Grid,
  Select,
  MenuItem,
} from "@mui/material";
import {
  purplePolygon,
  yellowPolygon,
  greenPolygon,
  planeVector,
} from "../../assets/index";
import TreatmentFormLabel from "../../Components/TreatmentFormLabel";
import { Download } from "@mui/icons-material";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import GenerateFileIcon from "./Components/GenerateFileIcon";
import SnackBarComponent from "../../Components/SnackBar/index";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import { postRequest, putRequest } from "../../connection/network";
import { useDispatch, useSelector } from "react-redux";
import { getAction } from "../../Store/Actions/MedicalTreatment";
import Loader from "../../Components/Loader";
import moment from "moment";

export default function TreatmentForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    t,
    i18n: { language: lang },
  } = useTranslation(["booking"]);

  const { id } = useParams();
  // const [data, setData] = useState([]);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState({});
  const [selectedFile, setSelectedFile] = useState("");

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const medicalData = useSelector(
    (state) => state.medicalTreatment?.data?.data
  );
  const loadingData = useSelector((state) => state.medicalTreatment.loading);
  const medical = medicalData?.length > 0 && medicalData[0];
  useEffect(() => {
    dispatch(getAction(id));
  }, [dispatch, id]);

  // const getSupplierType = useCallback(async () => {
  //   try {
  //     setLoading(true);
  //     const types = await listFreelanceSupplierTypeService();
  //     setData(types.data);
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //   }
  // }, []);

  // useEffect(() => {
  //   getSupplierType && getSupplierType();
  // }, [getSupplierType]);

  const formatFileSize = (size) => {
    const i = Math.floor(Math.log(size) / Math.log(1024));
    return (
      `${(size / Math.pow(1024, i)).toFixed(2) * 1} ` +
      ["B", "KB", "MB", "GB", "TB"][i]
    );
  };

  const services = [
    { label: t("accommodation"), value: "accommodation" },
    { label: t("flight"), value: "flight" },
    { label: t("transportation"), value: "transportation" },
    { label: t("medical_attendant"), value: "medicalAttendant" },
    { label: t("visa_additional"), value: "visaAdditional" },
  ];

  const validationSchema = Yup.object({
    explanations: Yup.string().required("Explanations is required"),
    // file: Yup.mixed()
    //   .required("Please upload a file")
    //   .test("fileSize", "File size must be less than or equal to 25 MB", () => {
    //     return selectedFile ? selectedFile.size <= 25000000 : true;
    //   }),
    services: Yup.array()
      .required("Select at least one additional service")
      .min(1, "Select at least one additional service"),
    firstName: Yup.string("first name")
      .min(3, "too short")
      .max(50, "too long")
      .required("First Name is required"),
    lastName: Yup.string("last name")
      .min(3, "too short")
      .max(50, "too long")
      .required("Last Name is required"),
    // middleName: Yup.string("middle name")
    //   .min(3, "too short")
    //   .max(50, "too long")
    //   .required("middle name is required"),
    gender: Yup.string("gender").required("Gender is required"),
    // dateBirth: Yup.date().max(new Date()).required("birth date"),
    email: Yup.string("Enter email")
      .email("Enter a valid email")
      .required("Email is required"),
    mobile: Yup.string()
      .min(11, "too short")
      .max(11, "too long")
      .required("phone required"),
  });

  const getServices = (servicesObject) => {
    if (!servicesObject) return;
    let result = [];
    Object.entries(servicesObject).forEach(([key, value]) => {
      (value === "true" || value === true) && result.push(key);
    });

    return result;
  };
  const initialValues = {
    explanations: medical?.explanations || "",
    file: medical?.file || null,
    services: getServices(medical?.services) || [],
    supplierType: medical?.supplierType || [],
    firstName: medical?.firstName || "",
    middleName: medical?.middleName || "",
    lastName: medical?.lastName || "",
    gender: medical?.gender || "",
    dateBirth: medical?.dateBirth ? moment(medical?.dateBirth) : null,
    email: medical?.email || "",
    mobile: medical?.mobile || "",
  };

  const handleSubmit = async (values, formikBag) => {
    const { setSubmitting } = formikBag;
    const formData = new FormData();
    formData.append("id", id);
    formData.append("explanations", values.explanations);
    selectedFile && formData.append("file", selectedFile);
    formData.append("firstName", values?.firstName);
    formData.append("lastName", values?.lastName);
    formData.append("email", values?.email);
    formData.append("mobile", values?.mobile);
    values?.middleName && formData.append("middleName", values?.middleName);
    values?.dateBirth && formData.append("dateBirth", values?.dateBirth);
    formData.append("gender", values?.gender);

    // values?.supplierType?.length &&
    //   values?.supplierType?.map((e) =>
    //     formData.append("freelanceSupplierTypeId[]", e)
    //   );
    values?.services.forEach((service) => {
      formData.append(
        `services[${service}]`,
        !!values.services.includes(service)
      );
    }, {});
    try {
      const token = localStorage.getItem("token");
      const response = await putRequest({
        endPoint: "medicalTreatment",
        token,
        body: formData,
      });

      if (response.status === 200) {
        setShowSnackbar(true);
        setSnackbarProps({
          open: true,
          Transition: Slide,
          isError: false,
        });

        setTimeout(() => {
          setShowSnackbar(false);
        }, 3000);
        dispatch(getAction(id));
      } else {
        setShowSnackbar(true);
        setSnackbarProps({
          open: true,
          Transition: Slide,
          isError: true,
        });

        setTimeout(() => {
          setShowSnackbar(false);
        }, 3000);
      }
    } catch (error) {
      setShowSnackbar(true);
      setSnackbarProps({
        open: true,
        Transition: Slide,
        isError: true,
      });

      setTimeout(() => {
        setShowSnackbar(false);
      }, 3000);

      console.error(error);
    }
    setSubmitting(false);
  };

  return (
    <>
      {loadingData ? (
        <Box
          sx={{
            width: 1,
            height: "100vh",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Loader />
        </Box>
      ) : (
        <>
          {showSnackbar && <SnackBarComponent {...snackbarProps} />}

          <Box
            sx={{
              position: "relative",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              gap: "4em",
              p: { xs: "2em", sm: "4em" },
            }}
          >
            <img
              src={purplePolygon}
              alt=""
              style={{
                position: "absolute",
                top: "50%",
                left: 0,
                transform: "rotate(180deg) translate(40%, 50%)",
                zIndex: -1,
              }}
            />

            <img
              src={yellowPolygon}
              alt=""
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                transform: "translate(20%, -50%)",
                zIndex: -1,
              }}
            />

            <img
              src={greenPolygon}
              alt=""
              style={{
                position: "absolute",
                bottom: 0,
                right: 0,
                transform: "rotate(270deg) translate(-100%, 30%)",
                zIndex: -1,
              }}
            />

            <img
              src={planeVector}
              alt=""
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                transform: "translate(0, 100%)",
                zIndex: -2,
              }}
            />

            <Typography
              variant="h4"
              sx={{ color: "#041716", fontWeight: "700" }}
            >
              {t("medical.plan")}
            </Typography>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              <Form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "4em",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Field name="firstName">
                      {({ field }) => (
                        <>
                          <TextField
                            {...field}
                            fullWidth
                            variant="filled"
                            label={`${t("e_visa.first_name")}*`}
                            placeholder={t("e_visa.first_name")}
                            sx={{
                              "& input": {
                                fontWeight: "400",
                                color: "red",
                              },
                              borderBottom: "none !important",
                            }}
                          />
                          <ErrorMessage
                            name={"firstName"}
                            component="div"
                            style={{
                              color: "red",
                              fontFamily: "Lato",
                            }}
                          />
                        </>
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Field name="middleName">
                      {({ field }) => (
                        <>
                          <TextField
                            {...field}
                            fullWidth
                            variant="filled"
                            label={`${t("e_visa.middle_name")}*`}
                            placeholder={t("e_visa.middle_name")}
                            sx={{
                              "& input": {
                                fontWeight: "400",
                                color: "red",
                              },
                              borderBottom: "none !important",
                            }}
                          />
                          <ErrorMessage
                            name={"middleName"}
                            component="div"
                            style={{
                              color: "red",
                              fontFamily: "Lato",
                            }}
                          />
                        </>
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Field name="lastName">
                      {({ field }) => (
                        <>
                          <TextField
                            {...field}
                            fullWidth
                            variant="filled"
                            label={`${t("e_visa.last_name")}*`}
                            placeholder={t("e_visa.last_name")}
                            sx={{
                              "& input": {
                                fontWeight: "400",
                                color: "red",
                              },
                              borderBottom: "none !important",
                            }}
                          />
                          <ErrorMessage
                            name={"lastName"}
                            component="div"
                            style={{
                              color: "red",
                              fontFamily: "Lato",
                            }}
                          />
                        </>
                      )}
                    </Field>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Field name="email">
                      {({ field }) => (
                        <>
                          <TextField
                            {...field}
                            fullWidth
                            variant="filled"
                            label={`${t("e_visa.email")}*`}
                            placeholder={t("e_visa.email")}
                            sx={{
                              "& input": {
                                fontWeight: "400",
                                color: "red",
                              },
                              borderBottom: "none !important",
                            }}
                          />
                          <ErrorMessage
                            name={"email"}
                            component="div"
                            style={{
                              color: "red",
                              fontFamily: "Lato",
                            }}
                          />
                        </>
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field name="mobile">
                      {({ field }) => (
                        <>
                          <TextField
                            {...field}
                            fullWidth
                            variant="filled"
                            label={`${t("e_visa.mobile")}*`}
                            placeholder={t("e_visa.mobile")}
                            sx={{
                              "& input": {
                                fontWeight: "400",
                                color: "red",
                              },
                              borderBottom: "none !important",
                            }}
                          />
                          <ErrorMessage
                            name={"mobile"}
                            component="div"
                            style={{
                              color: "red",
                              fontFamily: "Lato",
                            }}
                          />
                        </>
                      )}
                    </Field>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Field name="gender">
                      {({ field }) => (
                        <>
                          <Select
                            fullWidth
                            {...field}
                            variant="filled"
                            displayEmpty
                            inputProps={{ name: "gender" }}
                            renderValue={(selected) => {
                              if (!selected) {
                                return (
                                  <Typography color="text.icon">
                                    {t("e_visa.gender")}
                                  </Typography>
                                );
                              }

                              return selected;
                            }}
                          >
                            {[
                              { label: t("male"), value: "male" },
                              { label: t("female"), value: "female" },
                            ]?.map((e, i) => (
                              <MenuItem key={i} value={e?.value}>
                                {e?.label}
                              </MenuItem>
                            ))}
                          </Select>
                          <ErrorMessage
                            name={"gender"}
                            component="div"
                            style={{
                              color: "red",
                              fontFamily: "Lato",
                            }}
                          />
                        </>
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field name="dateBirth">
                      {({ field }) => (
                        <>
                          <LocalizationProvider
                            dateAdapter={AdapterMoment}
                            adapterLocale={"en-us"}
                          >
                            <DatePicker
                              {...field}
                              onChange={(value) =>
                                field?.onChange({
                                  target: { value, name: "dateBirth" },
                                })
                              }
                              slots={{
                                openPickerIcon: DateRangeOutlinedIcon,
                              }}
                              slotProps={{
                                inputAdornment: {
                                  position: "start",
                                },
                                textField: {
                                  fullWidth: true,
                                  variant: "filled",
                                },
                              }}
                            />
                          </LocalizationProvider>
                          <ErrorMessage
                            name={"dateBirth"}
                            component="div"
                            style={{
                              color: "red",
                              fontFamily: "Lato",
                            }}
                          />
                        </>
                      )}
                    </Field>
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "start",
                    gap: "1em",
                    flexDirection: { xs: "column", md: "row" },
                  }}
                >
                  <Box sx={{ flex: 1 }}>
                    <TreatmentFormLabel
                      title={t("explanations")}
                      description={t(
                        "Please write detailed explanations about your request in this field"
                      )}
                    />
                  </Box>

                  <Box sx={{ borderRadius: "10px", flex: 2, width: "100%" }}>
                    <Field name="explanations">
                      {({ field }) => (
                        <>
                          <TextField
                            {...field}
                            fullWidth
                            multiline
                            rows={4}
                            variant="filled"
                            placeholder={t(
                              "Please write your explanations here."
                            )}
                            sx={{
                              "& input": {
                                fontWeight: "400",
                                color: "red",
                              },
                              borderBottom: "none !important",
                              bgcolor: "#F2F2F2",
                            }}
                          />
                          <ErrorMessage
                            name={"explanations"}
                            component="div"
                            style={{
                              color: "red",
                              fontFamily: "Lato",
                            }}
                          />
                        </>
                      )}
                    </Field>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "start",
                    gap: "1em",
                    flexDirection: { xs: "column", md: "row" },
                  }}
                >
                  <Box sx={{ flex: 1 }}>
                    <TreatmentFormLabel
                      title={t("files")}
                      description={t(
                        "You can upload RAR, ZIP, PDF, Word, Excel, JPG files. | Maximum File Size 25 MB, Total File Size 100 MB"
                      )}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.5em",
                      flex: 2,
                      width: "100%",
                    }}
                  >
                    <Field name="file">
                      {({ field }) => (
                        <Box
                          sx={{
                            borderRadius: "10px",
                            bgcolor: "#F2F2F2",
                            p: "2em",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "1em",
                          }}
                        >
                          {selectedFile ? (
                            <>
                              {/* File Icon based on file type */}
                              {
                                <GenerateFileIcon
                                  fileType={selectedFile.type}
                                />
                              }
                              <Typography
                                variant="body1"
                                sx={{ color: "#565656" }}
                              >
                                {selectedFile.name} (
                                {formatFileSize(selectedFile.size)})
                              </Typography>
                            </>
                          ) : (
                            <>
                              <Download
                                sx={{
                                  color: "#CECECE",
                                  width: "50px",
                                  height: "50px",
                                }}
                              />
                              <Typography
                                variant="body1"
                                sx={{ color: "#565656" }}
                              >
                                {t("Drag and drop files")}
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{ color: "#565656" }}
                              >
                                {t("or")}
                              </Typography>
                            </>
                          )}
                          <label
                            htmlFor="file-upload"
                            style={{ cursor: "pointer" }}
                          >
                            <Input
                              {...field}
                              id="file-upload"
                              type="file"
                              style={{ display: "none" }}
                              onChange={(event) => {
                                field.onChange(event);
                                handleFileUpload(event);
                              }}
                            />
                            <Button
                              variant="contained"
                              component="span"
                              sx={{ bgcolor: "primary.main" }}
                            >
                              <Typography
                                variant="button"
                                sx={{ color: "#030C16", fontWeight: "600" }}
                              >
                                {t("Browse")}
                              </Typography>
                            </Button>
                          </label>

                          <ErrorMessage
                            name="file"
                            component="div"
                            style={{ color: "red", fontFamily: "Lato" }}
                          />
                        </Box>
                      )}
                    </Field>
                    {!selectedFile && (
                      <Typography variant="caption" sx={{ color: "#969696" }}>
                        {t(
                          "You haven't uploaded a file yet. You can upload a file by selecting or dragging a file above."
                        )}
                      </Typography>
                    )}
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "start",
                    gap: "1em",
                    flexDirection: { xs: "column", md: "row" },
                  }}
                >
                  <Box sx={{ flex: 1 }}>
                    <TreatmentFormLabel
                      title={t("additional_services")}
                      description={t(
                        "choose from the following additional services."
                      )}
                    />
                  </Box>
                  <Box sx={{ flex: 2, width: "100%" }}>
                    <Field name="services">
                      {({ field }) => (
                        <>
                          {services?.map((service, index) => (
                            <>
                              <Box
                                key={index}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box sx={{ ml: 1 }}>
                                  <Typography
                                    variant="body1"
                                    sx={{ color: "#4C4C4C", fontWeight: "500" }}
                                  >
                                    {service?.label}
                                  </Typography>
                                </Box>
                                <Checkbox
                                  {...field}
                                  sx={{ color: "#A6A6A6" }}
                                  value={service?.value}
                                  checked={field.value.includes(service?.value)}
                                />
                              </Box>
                              {index !== services.length - 1 && (
                                <Divider sx={{ borderColor: "#D9D9D9" }} />
                              )}
                            </>
                          ))}
                          <ErrorMessage
                            name="services"
                            component="div"
                            style={{ color: "red" }}
                          />
                        </>
                      )}
                    </Field>
                    <Divider sx={{ borderColor: "#D9D9D9" }} />
                    {/* {loading ? (
                  <Loader />
                  ) : (
                  <Field name="supplierType">
                    {({ field }) => (
                      <>
                      {data?.map((e, index) => (
                        <>
                        <Box
                        key={index}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box sx={{ ml: 1 }}>
                                <Typography
                                  variant="body1"
                                  sx={{ color: "#4C4C4C", fontWeight: "500" }}
                                >
                                  {e?.name[lang]}
                                </Typography>
                              </Box>
                              <Checkbox
                                {...field}
                                sx={{ color: "#A6A6A6" }}
                                value={e?.id}
                                />
                            </Box>
                            {index !== data?.length - 1 && (
                              <Divider sx={{ borderColor: "#D9D9D9" }} />
                            )}
                          </>
                        ))}
                        </>
                    )}
                  </Field>
                )} */}

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "2em",
                        mt: "4em",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: "1em",
                          flexDirection: { xs: "column", sm: "row" },
                        }}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          sx={{ bgcolor: "primary.main", p: "0.5em 3em" }}
                        >
                          <Typography sx={{ color: "#002222" }}>
                            Submit
                          </Typography>
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Form>
            </Formik>
          </Box>
        </>
      )}
    </>
  );
}
