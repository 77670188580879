import {
  LIST_NOTIFICATIONS,
  LIST_NOTIFICATIONS_ERROR,
  LIST_NOTIFICATIONS_LOADING,
  INSERT_NEW_NOTIFICATION,
  UPDATE_UN_READ,
} from "../Actions/types";
import { createReducer } from "@reduxjs/toolkit";

const initials = {
  data: [],
  thisPage: 0,
  allPages: 0,
  error: false,
  totalUnRead: 0,
};

const notifyReducer = createReducer(initials, (builder) => {
  builder
    .addCase(UPDATE_UN_READ, (s, { payload }) => {
      s.totalUnRead = payload.unReadNotificationsCount;
    })
    .addCase(INSERT_NEW_NOTIFICATION, (s, { payload }) => {
      s.totalUnRead++;
      s.data.unshift(payload);
    })
    .addCase(LIST_NOTIFICATIONS_LOADING, (s) => {
      s.loading = true;
    })
    .addCase(LIST_NOTIFICATIONS_ERROR, (s, { payload }) => {
      s.error = payload;
    })
    .addCase(LIST_NOTIFICATIONS, (s, { payload }) => {
      s.data =
        payload?.thisPage === 1
          ? payload?.data
          : [...s?.data, ...payload?.data];
      s.count = payload.count;
      s.allPages = payload.allPages;
      s.thisPage = payload.thisPage;
      s.loading = false;
      s.totalUnRead = 0;
    });
});

export default notifyReducer;
