import { createReducer } from "@reduxjs/toolkit";
import {
  ROLES_LOADING,
  ROLES_ERROR,
  GET_ROLES,
  CREATE_ROLE,
} from "../Actions/types";

const initialState = {
  error: "",
  loading: false,
  data: [],
  thisPage: 0,
  nextPage: 0,
  hasMore: true,
  count: 0,
};

const rolesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(ROLES_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(ROLES_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(GET_ROLES, (state, { payload }) => {
      state.data =
        payload?.thisPage === 1
          ? payload?.data
          : [...state?.data, ...payload?.data];
      state.thisPage = payload.thisPage;
      state.nextPage = payload.nextPage;
      state.hasMore = payload.hasMore;
      state.count = payload.count;
      state.loading = false;
    })
    .addCase(CREATE_ROLE, (state, { payload }) => {
      state.data.unshift(payload);
      state.loading = false;
      state.error = false;
    });
});

export default rolesReducer;
