import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  loading: false,
  error: null,
  charts: {},
};

const visaSlice = createSlice({
  name: "visa",
  initialState,
  reducers: {
    loading(state) {
      state.loading = true;
      state.error = null;
    },
    error(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    setData(state, action) {
      state.loading = false;
      state.data = action.payload;
    },
    setChartData(state, action) {
      state.loading = false;
      const charts = {};
      action.payload.forEach((e, i) => {
        charts[e?.Category] = charts[e?.Category]?.length
          ? [
              ...charts[e?.Category],
              { label: e?.Value || "Unspecified", value: e?.Count, id: i },
            ]
          : [{ label: e?.Value || "Unspecified", value: e?.Count, id: i }];
      });
      state.charts = charts;
    },
    statusData(state, action) {
      const foundProduct = state.data.filter(
        (e) => e.id === action.payload?.data?.id
      )[0];
      const index = state.data.indexOf(foundProduct);
      state.data[index] = { ...state.data[index], ...action.payload?.data };
      state.loading = false;
    },
    assignData(state, action) {
      const foundProduct = state.data.filter(
        (e) => e.id === action.payload?.id
      )[0];

      const index = state.data.indexOf(foundProduct);

      state.data[index] = {
        ...state.data[index].medicalSupplier,
        ...action.payload?.data?.freelanceSuppliers,
      };
      state.loading = false;
    },
  },
});

export const { loading, error, setData, setChartData, statusData, assinData } =
  visaSlice.actions;

export default visaSlice.reducer;
