import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  loading: false,
  error: null,
  charts: {},
};

const plansSlice = createSlice({
  name: "plans",
  initialState,
  reducers: {
    loading(state) {
      state.loading = true;
      state.error = null;
    },
    error(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    setData(state, action) {
      state.loading = false;
      state.data = action.payload;
    },
    setChartData(state, action) {
      state.loading = false;
      state.charts = action.payload;
    },
    assignData(state, action) {
      const foundProduct = state.data.filter(
        (e) => e.id === action.payload?.id
      )[0];

      const index = state.data.indexOf(foundProduct);

      state.data[index] = {
        ...state.data[index].medicalSupplier,
        ...action.payload?.data?.freelanceSuppliers,
      };
      state.loading = false;
    },
  },
});

export const { loading, error, setData, setChartData, assignData } =
  plansSlice.actions;

export default plansSlice.reducer;
