import store from "../Store";
import { SET_CURRENT_USERS } from "../Store/Actions/types";
import { fetchSingleUser, fetchUsers } from "./users";

export const clearStorageChat = (chatId) => {};
export const storeChatsLocalStorageInFirstTime = (
  { chats, thisPage, allPages },
  clear = true
) => {
  if (clear) localStorage.removeItem("dashboard_chats");
  let currentChats = {};
  chats.map(
    (c) =>
      (currentChats[c.id] = {
        ...c,
        messages: { data: [], meta: null },
      })
  );
  localStorage.setItem(
    "dashboard_chats",
    JSON.stringify({ byId: currentChats, thisPage, allPages })
  );
};

export const addMessagToLocalStorage = (chatId, data) => {
  let currentChats = localStorage.getItem("dashboard_chats");
  if (!currentChats) {
    currentChats = { byId: {} };
  } else {
    currentChats = JSON.parse(currentChats);
  }
  let chat = currentChats.byId[chatId];
  if (chat) {
    let messageFound = currentChats.byId[chatId]["messages"]["data"]?.findIndex(
      (m) =>
        (data.feMark && parseInt(data.feMark) === m.feMark) ||
        (data.uuid && data.uuid === m.uuid)
    );
    if (messageFound > -1) {
      currentChats.byId[chatId]["messages"]["data"][messageFound] = {
        ...currentChats.byId[chatId]["messages"]["data"][messageFound],
        ...data,
        status: "sent",
      };
    } else {
      currentChats.byId[chatId]["messages"]["data"].push(data);
    }
    localStorage.setItem("dashboard_chats", JSON.stringify(currentChats));
    return true;
  }
  return false;
};

export const addChatToLocalStorageChats = (id, object) => {
  let currentChats = localStorage.getItem("dashboard_chats");
  if (!currentChats) {
    currentChats = { byId: {} };
  } else {
    currentChats = JSON.parse(currentChats);
  }
  currentChats.byId[id] = { ...object };

  return localStorage.setItem("dashboard_chats", JSON.stringify(currentChats));
};

export const loadMoreMessagesToLocalStorage = (chatId, messagesData) => {
  let currentChats = localStorage.getItem("dashboard_chats");
  if (!currentChats) {
    currentChats = { byId: {} };
  } else {
    currentChats = JSON.parse(currentChats);
  }
  let chat = currentChats.byId[chatId];
  if (chat) {
    const { data, meta, hasMore } = messagesData;
    currentChats.byId[chatId]["messages"] = {
      data: [...data, ...(currentChats.byId[chatId]["messages"]?.data || [])],
      ...meta,
      meta,
      hasMore,
    };
  }
  localStorage.setItem("dashboard_chats", JSON.stringify(currentChats));
};

export const storeFirstTimeChatLocalStorage = (chatId, data) => {
  let currentChats = localStorage.getItem("dashboard_chats");
  if (!currentChats) {
    currentChats = { byId: {} };
  } else {
    currentChats = JSON.parse(currentChats);
  }
  let chat = currentChats.byId[chatId];
  if (chat) {
    currentChats.byId[chatId] = { ...data, fetched: true };
  }
  localStorage.setItem("dashboard_chats", JSON.stringify(currentChats));
};

export const getChatFromLocalStorage = (chatId) => {
  let currentChats = localStorage.getItem("dashboard_chats");
  if (!currentChats) {
    currentChats = { byId: {} };
  } else {
    currentChats = JSON.parse(currentChats);
  }
  let chat = currentChats?.byId[chatId];
  if (chat) {
    let messages = chat.messages;
    delete chat.messages;
    let otherUser = chat?.otherUser;
    if (otherUser) {
      chat.otherUser = fetchSingleUser(otherUser?.id);
    }

    if (chat?.fetched) {
      store.dispatch({
        type: SET_CURRENT_USERS,
        payload: fetchUsers([...(chat?.users || []), otherUser && otherUser]),
      });
    }

    return {
      chat,
      messages,
      hasMore: chat?.hasMore || chat?.messages?.hasMore,
    };
  }
  return null;
};

export const changeMessagesUUIDLocalStorage = (chatId, feMark, data) => {
  let currentChats = localStorage.getItem("dashboard_chats");
  if (currentChats) {
    currentChats = JSON.parse(currentChats);
  } else {
    if (!currentChats) {
      currentChats = { byId: {} };
    }
  }
  let chat = currentChats?.byId[chatId];
  let mIdx = chat?.messages?.data.findIndex(
    (m) => m.feMark === parseInt(feMark)
  );
  if (mIdx > -1) {
    let m = currentChats.byId[chatId].messages.data[mIdx];
    if (m.files) {
      delete currentChats.byId[chatId].messages.data[mIdx].files;
    }
    currentChats.byId[chatId].messages.data[mIdx] = {
      ...chat?.messages?.data[mIdx],
      ...data,
    };
  }
  localStorage.setItem("dashboard_chats", JSON.stringify(currentChats));
};

export const changeMessageUUIDTypeLocalStorage = (typeData) => {
  let { feMark, uuid, messageId, chatId } = typeData;
  let currentChats = localStorage.getItem("dashboard_chats");
  if (!currentChats) {
    currentChats = { byId: {} };
  } else {
    currentChats = JSON.parse(currentChats);
  }
  let chat = currentChats?.byId[chatId];

  const mIdx = chat.messages.data.findIndex(
    (c) =>
      (c.id && c.id === messageId) ||
      (c.uuid && c.uuid === uuid) ||
      (c.feMark && c.feMark === feMark)
  );
  if (mIdx > -1) {
    currentChats.byId[chatId].messages.data[mIdx]["type"] = {
      ...typeData,
    };
  }
  localStorage.setItem("dashboard_chats", JSON.stringify(currentChats));
};

export const changeLOMessage = ({ uuids, newToUpdate = {}, chatId }) => {
  let currentChats = localStorage.getItem("dashboard_chats");
  if (!currentChats) {
    currentChats = { byId: {} };
  } else {
    currentChats = JSON.parse(currentChats);
  }
  let chat = currentChats?.byId[chatId];

  if (chat) {
    uuids.map((uuid) => {
      const mIdx = chat.messages.data.findIndex(
        (c) => c.uuid && c.uuid === uuid
      );
      let oldMessage = currentChats.byId[chatId].messages.data[mIdx];
      return (currentChats.byId[chatId].messages.data[mIdx] = {
        ...oldMessage,
        ...newToUpdate,
      });
    });

    localStorage.setItem("dashboard_chats", JSON.stringify(currentChats));
  }
};
