import { useSelector } from "react-redux";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Navigate, useParams, useSearchParams } from "react-router-dom";
import Loader from "../../Components/Loader";
import {
  createPermissionsService,
  listPermissionsService,
} from "../../Services/permissions";
import PermissionsRow from "./PermissionsRow";

const headers = ["Module", "Show", "Create", "Edit", "Delete"];

const Permissions = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const isSuperAdmin = searchParams.get("isSuperAdmin");

  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [modules, setModules] = useState([]);

  const getPermissions = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await listPermissionsService({ roleId: id });
      setModules(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    getPermissions && getPermissions();
  }, [getPermissions]);

  const changePermissions = useCallback(
    (entityId) => (e) => {
      setModules((prev) => {
        const temp = [...prev];
        const index = temp?.findIndex((e) => e?.id === entityId);
        temp[index].permissions = {
          ...temp[index]?.permissions,
          [e.target.name]: e.target.checked,
        };
        return temp;
      });
    },
    []
  );

  const submitPermissions = useCallback(async () => {
    try {
      setLoadingSubmit(true);
      const sendData = modules?.map((entity) => ({
        module: entity?.id,
        roleId: id,
        canRead: entity?.permissions?.canRead || false,
        canCreate: entity?.permissions?.canCreate || false,
        canUpdate: entity?.permissions?.canUpdate || false,
        canDelete: entity?.permissions?.canDelete || false,
      }));
      await createPermissionsService({ sendData: { permissions: sendData } });
      setLoadingSubmit(false);
    } catch (error) {
      setLoadingSubmit(false);
    }
  }, [modules, id]);

  const isAuthenticated = useSelector((s) => s?.auth?.isAuthenticated);

  let currentLocationForRedirect = useMemo(() => {
    return window.location.href?.split(window?.location?.origin);
  }, []);
  if (!isAuthenticated)
    return (
      <Navigate
        to="/login"
        state={{
          currentLocationForRedirect: currentLocationForRedirect
            ? currentLocationForRedirect[1]
            : null,
        }}
        replace
      />
    );

  return (
    <>
      <Container maxWidth="xl" sx={{ pt: 5 }}>
        {loading ? (
          <Box sx={{ display: "grid", placeItems: "center", py: 5 }}>
            <Loader />
          </Box>
        ) : (
          <>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {headers?.map((header, index) => (
                      <TableCell
                        key={index}
                        sx={{ bgcolor: "#FAFAFA", fontWeight: "bold" }}
                      >
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {modules?.map((entity) => (
                    <PermissionsRow
                      key={entity?.id}
                      entity={entity}
                      disabled={loadingSubmit}
                      changePermissions={changePermissions(entity?.id)}
                      permissions={entity?.permissions || {}}
                      isSuperAdmin={isSuperAdmin}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Button
              variant="contained"
              onClick={submitPermissions}
              disabled={isSuperAdmin || loadingSubmit}
              sx={{ my: 8 }}
            >
              Submit
            </Button>
          </>
        )}
      </Container>
    </>
  );
};

export default Permissions;
